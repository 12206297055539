import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";

import {useDispatch, useSelector} from "react-redux";
import {increment, setAmount} from "./counterSlice";

import {
    getCollectibles, getCustomerParams,
    getLoyaltyData, getPointsLimits, registerInAffiliate, flySignup as flySignupApi
} from "../../logic/server/api";
import {useTelegram} from "../../hooks/useTelegram";
import AnimationScreen from "./AnimationScreen";
import {incrementBlinkByAmount} from "./blinkSlice";
import {APP_VERSION} from "../../logic/server/Variables";

const Main = () => {
    // const navigate = useNavigate();
    const {webApp, user} = useTelegram();

    // const customer = useSelector(state => state.customer.customer);
    // const loyaltyData = useSelector(state => state.loyalty.loyalty);
    const dispatch = useDispatch();
    // const [audioElm] = useState(new Audio(audio));
    // const [audioElmSleep] = useState(new Audio(audioSleep));

    const [sleepMode, setSleepMode] = useState(false);
    const [sleepPoints, setSleepPoints] = useState([]);
    const [energy, setEnergy] = useState(5000);
    const [open, setOpen] = useState(false)
    const [collectibles, setCollectibles] = useState([]);
    const [customer, setCustomer] = useState({customer_id: null, token: null});
    const isStarted = useRef(false);
    const initData = webApp.initData;
    const code = localStorage.getItem('code') ? `?start=${localStorage.getItem('code')}` : '';

    const customer_id = new URLSearchParams(window.location.search)?.get('customer_id');

    const isAppOpenedSaved = useSelector(state => state.settings.isOpened);
    localStorage.setItem('openState', isAppOpenedSaved);

    useEffect(() => {
        const storedVersion = localStorage.getItem('app_version');
        if (storedVersion !== APP_VERSION) {
            localStorage.setItem('time', '');
            localStorage.setItem('sleepTime', '');
            localStorage.setItem('sleepTime', '');
            localStorage.setItem('openState', '');
            localStorage.setItem('app_version', APP_VERSION);
        }
        if ((initData || customer_id || localStorage.getItem('token')) && !isStarted.current) {
            getCustomer();
            // getWallet();
        } else if (!initData && !customer_id && !isStarted.current) {
            isStarted.current = true;
            flySignup();
        }
    }, []);

    async function flySignup() {
        const signupBody = {
            login: generateRandomString(8),
            password: generateRandomString(12),
            profile: {}
        };
        const flySignupResp = await flySignupApi(signupBody);
        if (flySignupResp && flySignupResp.status && flySignupResp.customer_id && flySignupResp.token) {
            localStorage.setItem('token', flySignupResp.token);
            localStorage.setItem('customer', flySignupResp.customer_id);
            setCustomer({ customer_id: flySignupResp.customer_id, token: flySignupResp.token });
            getCustomer(flySignupResp.customer_id, flySignupResp.token);
        }
    }

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };



    useEffect(() => {
        if (customer?.customer_id && customer?.token) {
            getCollectiblesItems();
        }
    }, [customer]);

    async function getCollectiblesItems(){
       const resp = await getCollectibles();
       if (resp?.status) {
          setCollectibles(resp.items);
       }
    }

    async function getAffiliatesData(customerInfo) {
        const resp2 = await getLoyaltyData(customerInfo.customer_id, customerInfo.token);

        if (resp2 && resp2?.data?.reward?.loyalty_points) {
            const points = resp2?.data?.reward?.loyalty_points;
            localStorage.setItem('code', resp2?.data?.referral_code);

            dispatch(setAmount(points));
            localStorage.setItem('points', points);
        }
        if (!resp2 || !resp2?.data?.applied) {
            const resp3 = await registerInAffiliate(customerInfo.customer_id, customerInfo.token);
            if (resp3?.referral_code) {
                localStorage.setItem('code', resp3?.referral_code);
            }
        }
    }

    async function getCustomer() {
        const initDataURL = new URLSearchParams(initData);
        const referralCodeInitial = initDataURL?.get('start_param');
        isStarted.current = true;
        const params = new URLSearchParams(window.location.search);
        let customerInfo = {customer_id: params?.get('customer_id'), token: params?.get('token')};

        if ((!customerInfo.customer_id || !customerInfo.token) && initData) {
            const resp = await getCustomerParams(initData, referralCodeInitial);
            if (resp?.status) {
               customerInfo = { customer_id: resp.customer?.customer_id, token: resp.customer?.token };
            }
        }
        if ((customerInfo && customerInfo.customer_id && customerInfo.token) || localStorage.getItem('token')) {
            if (customerInfo?.customer_id) {
                localStorage.setItem('token', customerInfo?.token);
                localStorage.setItem('customer', customerInfo?.customer_id);

                const resp5 = await getCollectibles();
                if (resp5?.status) {
                    setCollectibles(resp5.items);
                }
            }

            // for localhost
            if (!customerInfo.customer_id && localStorage.getItem('token')) {
                customerInfo = {customer_id: localStorage.getItem('customer'), token: localStorage.getItem('token')};
            }
            setCustomer(customerInfo);

            getAffiliatesData(customerInfo);
            // if user go by referral link first time
            if (referralCodeInitial) {
                setTimeout(() => {
                    getAffiliatesData(customerInfo);
                }, 10*1000);
            }

            const resp4 = await getPointsLimits(customerInfo.token);
            const remaining = resp4?.task?.completion?.limits?.reward?.per_user?.per_period?.remaining;
            if (remaining === 0) {
                setSleepMode(true);
                setEnergy(0);
                // localStorage.setItem('sleepMode', JSON.stringify(true));
            } else if (remaining) {
                setEnergy(remaining);
                // localStorage.setItem('sleepMode', JSON.stringify(false));
            }
        }
    }


return <AnimationScreen collectibles={collectibles} customer={customer}></AnimationScreen>
};

export default Main;
