import React, {useEffect, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
// import {useTelegram} from "../../hooks/useTelegram";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {ReactComponent as UserIcon} from '../../assets/solar_users-group-rounded-bold.svg';
import {ReactComponent as VideoIcon} from '../../assets/solar_video-library-bold.svg';
import {ReactComponent as ArrowIcon} from '../../assets/angle-right-1.svg';
import {ReactComponent as CheckIcon} from '../../assets/check-circle.svg';
import {claimPoints, getTasks, setInteraction} from "../../logic/server/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTelegram} from "../../hooks/useTelegram";
const TasksPage = () => {
    const {webApp, user} = useTelegram();
    // const [tasks, setTasks] = useState([]);
    const [tasksFollowPartner, setPartnersTasks] = useState([]);
    const [tasksFollowChannel, setChannelTasks] = useState([]);
    const [linkOpened, setLinkOpened] = useState([]);
    const [taskClaimed, setTaskClaimed] = useState([]);

    useEffect(() => {
        getTasksRequest();
    }, []);

    useEffect(() => {
        getTasksRequest();
    }, [linkOpened, taskClaimed]);

    async function getTasksRequest() {
        const resp = await getTasks();
        const followChannel = resp?.tasks?.filter(item => item?.conditions[0]?.type === 'interaction-tracker.link-visited');
        setChannelTasks(followChannel?.length ? followChannel : []);
        // console.log(resp);
    }

   async function handleInteraction(task) {
        if (task?.conditions[0].data?.link && (!task?.completion.claimable && task?.completion.status !== 'completed' )) {
            // console.log(task);
            const res = await setInteraction(task?.conditions[0].data?.link);
            // window.open(task?.conditions[0].data?.link, '_blank');
            // window.location.assign(task?.conditions[0].data?.link);
            webApp.openLink(task?.conditions[0].data?.link);

            // window.location.href = task?.conditions[0].data?.link;
           if (res?.status) {
               setLinkOpened([{id: task.id, status: true}]);
           }
        } else if (task?.completion.claimable) {
            handleClaim(task);
            toast.success(`You claimed ${task?.reward?.data?.value} DINKS successfully!`);

        }
    }

    async function handleClaim(task) {
        const res = await claimPoints( localStorage.getItem('token'), task.id);
        if (res?.status) {
            setTaskClaimed([{id: task.id, status: true}]);
        }
    }

        return (
        <TelegramScreen
            className={`main-wrapper white-background`}  id='gesuredZone'>
            <div className='picklezone-container picklezone-container-dim'>
                <div className={'cards-container--top'}>
                <div className={'card__tasks--title '}>
                    
                    <p className={'text-bold-roboto'}>{[...tasksFollowChannel, ...tasksFollowPartner].length} {[...tasksFollowChannel, ...tasksFollowPartner].length > 1 ? 'tasks' : 'task'} available</p>
                </div>
                <div className={'cards__container'}>
                    {tasksFollowPartner.map((task) => (
                        <div className={'new-picklezone-card__task'}
                             key={task}
                        >
                    
                        <div className={'picklezone-card__column-to-row'}>
                            <p className={'picklezone-card__task--text'}>{task?.title}</p>
                            <p className={'small-card-task-text'}>{task?.reward?.data?.value} DINKS for task</p>
                        </div>
                            <div className={'picklezone-claim-row'}>
                             <UserIcon/>
                        <p className={'claim-text-card'}>CLAIM NOW</p>
                            </div>
                    </div>
                    ))}
                    {tasksFollowChannel.map((task) => (
                    <div className={`new-picklezone-card__task ${task?.completion.status === 'completed' ?  'disabled--bright' :''}`}
                         onClick={() => handleInteraction(task)}>
                        <div className={'picklezone-card__column-to-row'}>
                            <p className={'picklezone-card__task--text'}>{task?.title}</p>
                            <p className={'small-card-task-text'}>{task?.reward?.data?.value} DINKS for task</p>
                            {task?.completion.claimable && <div className={'picklezone-claim-row'}>
                                <VideoIcon/>
                                <p className={'claim-text-card'} >CLAIM NOW</p>
                            </div>}
                        </div>

                        {(!task?.completion.claimable && task?.completion.status !== 'completed') &&
                            <ArrowIcon style={{width: '24px', height: '24px'}}></ArrowIcon>}
                        {(task?.completion.status === 'completed') &&
                            <CheckIcon  style={{width: '24px', height: '24px'}}></CheckIcon>}
                    </div>
                    ))}
                 </div>
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'tasks'}/>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>)

}
export default TasksPage;
