import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import React, {useEffect, useState} from "react";
import {ReactComponent as PersonIcon} from '../../assets/person-img.svg';
import {claimPoints, getEarnings, getReferrals, getTasks} from "../../logic/server/api";
import {toast} from "react-toastify";
import {useSpring, useSpringRef} from "@react-spring/web";
import {
    animated,
} from '@react-spring/web';
const EarningsPage = () => {
    const [earnings, setEarnings] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [referralsTasks, setReferralsTasks] = useState([]);
    const [currentTask, setCurrentTask] = useState({});
    const [taskClaimed, setTaskClaimed] = useState([]);

    // animation
    const springApi = useSpringRef()
    const [props, api] = useSpring(
        () => ({
            from: { opacity: 0.2 },
            to: { opacity: 1 },
            config: {
                duration: 1000,
            },
            ref: springApi
        }),
        []
    )

    useEffect(() => {
        getTasksRequest();
        getReferralsList();
        springApi.start();
    }, [taskClaimed]);

    const code = localStorage.getItem('code') ? `?start=${localStorage.getItem('code')}` : '';
    const shareUrl = `https://t.me/share/url?url=https://t.me/PicklezoneGameBot/PicklezoneGame` + code + `&text=Join Picklezone Game!`;
    const copyLink = `https://t.me/PicklezoneGameBot/PicklezoneGame` + code;
    async function getTasksRequest() {
        const resp = await getTasks();
        if (resp?.status) {
            const referralTasks = resp?.tasks?.sort((a, b) => a?.conditions[0]?.data?.number - b?.conditions[0]?.data?.number).
            filter(task => task?.conditions[0]?.type === 'referral-number' && (task?.completion?.status !== 'completed'))
            setReferralsTasks(referralTasks?.length ? referralTasks : []);
            setCurrentTask(referralTasks[0]);
            // console.log('tasks', resp);
        }
    }

    async function onClaimTask(task) {
        const res = await claimPoints( localStorage.getItem('token'), task.id);
        if (res?.status) {
            setTaskClaimed([{id: task.id, status: true}]);
            toast.success(`You claimed ${task?.reward?.data?.value} DINKS successfully!`);
        }
    }

    async function getReferralsList() {
        const resp = await getReferrals();
        if (resp.status) {
            const referralsSorted = resp?.referrals.sort((a, b) => b?.affiliate_programs[0].reward?.loyalty_points - a?.affiliate_programs[0].reward?.loyalty_points);
            setReferrals(referralsSorted);
        }
        // console.log(resp);
    }

    async function onCopyLink() {
       await navigator.clipboard.writeText(copyLink);
    }

    return (
        <TelegramScreen
            className={`main-wrapper white-background`}  id='gesuredZone'>
            <div className='picklezone-container picklezone-container-dim'>
                <div className={'cards-container--referral'}>
                    <div className='referrals-number--container'>
                        <div className='referrals-number--number referrals-number--dim'>{referrals.length >= 1 ? referrals.length - 1 : 0}</div>
                        <p className='referrals-number--space'>  </p>
                        <div className='referrals-number--number'>{referrals.length}</div>
                        <p className='referrals-number--space'></p>
                        <div className='referrals-number--number referrals-number--dim'>{referrals.length + 1}</div>
                    </div>
                    <animated.div style={props}>
                        {currentTask?.conditions?.length &&
                        <div className={`referral-task-container ${currentTask?.completion?.claimable ? 'referral-task-container--claimable' : ''}`}>
                        <div className={`referral-task-container--text ${currentTask?.completion?.claimable ? 'referral-task-container--text-claimable' : ''}`}>
                            Invite {currentTask?.conditions[0]?.data?.number} friends and receive {currentTask?.reward?.data?.value} DINKS</div>
                        {!currentTask?.completion?.claimable &&
                            <a className={'referral-task-container--invite'} href={shareUrl}>INVITE NOW</a>}
                        {currentTask?.completion?.claimable &&
                            <button className={`referral-task-container--invite ${currentTask?.completion?.claimable ? 'referral-task-container--invite-claimable' : ''}`}
                        onClick={() => onClaimTask(currentTask)}>CLAIM NOW</button>}
                    </div>}
                    </animated.div>
                </div>

                    <div className={'cards__container--earnings'}>
                        <div className='friends-card'>
                            <p className='friends-card--title'>{referrals.length} friends</p>
                            <p className='friends-card--description'>Registered with your referral link</p>
                        </div>
                        {referrals.map((referral) => (
                            <div className={'earning-card__task'}
                                 key={referral?.customer?.name}
                            >
                               <div className={'earning-card-block'}> 
                                <PersonIcon/>
                                <div className={'card__column-to-row'}>
                                <p className={'text-name-roboto'}>{referral?.customer?.name}</p></div></div>
                                <p className={'blue-text-name-roboto'}>{referral?.affiliate_programs[0].reward?.loyalty_points}</p>
                            </div>
                        ))}

                    </div>

                <div className='forms__bottom'>
                    
                    <div className={'picklezone-forms-wrapper'}>
                    <div className={'picklezone-forms-container'}>
                        <button className={'btn-for-copy-links'}
                            onClick={() => onCopyLink()}>
                            Copy invitation link</button></div>
                    <FooterFunction currentPage={'earnings'}/>
                    </div>
                </div>
            </div>
        </TelegramScreen>)


}

export default EarningsPage;
